import React from 'react'
import { Text } from '@mantine/core'

import icon from './icon.png'
import { createUseStyles } from 'react-jss'

export const TrackishText = ({
  style = {},
}: {
  style?: React.CSSProperties
}) => {
  return (
    <Text size="xl" style={style}>
      tracki.sh
    </Text>
  )
}

export const TrackishLogo = () => {
  return (
    <div
      style={{
        height: '2rem',
        width: '2rem',
      }}
    >
      <img src={icon} />
    </div>
  )
}

const useStyles = createUseStyles({
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
})

export const TrackishLogoAndText = ({
  style = {},
}: {
  style?: React.CSSProperties
}) => {
  const styles = useStyles()

  return (
    <div style={style} className={styles.logo}>
      <TrackishLogo />
      <TrackishText
        style={{
          marginLeft: '0.25rem',
          fontFamily: 'Abril Fatface',
        }}
      />
    </div>
  )
}

export default TrackishLogoAndText

import React, { ReactNode } from 'react'
import { Title, Text, Breadcrumbs, Grid, Col } from '@mantine/core'
import { Link } from 'gatsby'

import useIsMobile from '@hooks/useIsMobile'

const PageTitle = ({
  title,
  subtitle,
  rightSection = null,
  breadcrumbs = [],
}: {
  title: ReactNode
  subtitle: ReactNode
  rightSection?: ReactNode
  breadcrumbs?: {
    text: string
    to: string
  }[]
}) => {
  const isMobile = useIsMobile()

  return (
    <>
      {(breadcrumbs?.length ?? 0) > 0 && (
        <Breadcrumbs style={{ marginBottom: '1rem' }}>
          {breadcrumbs?.map(bc => (
            <Text variant="link" component={Link} to={bc.to} key={bc.to}>
              {bc.text}
            </Text>
          ))}
          <Text>{title}</Text>
        </Breadcrumbs>
      )}
      <Grid columns={isMobile ? 1 : 2} style={{ marginBottom: '1.5rem' }}>
        <Col span={1}>
          <Title order={1} style={{ marginBottom: '0.5rem' }}>
            {title}
          </Title>
          <Text size="xl">{subtitle}</Text>
        </Col>
        <Col span={1} style={{ display: isMobile ? 'block' : 'flex' }}>
          {rightSection}
        </Col>
      </Grid>
    </>
  )
}

export default PageTitle

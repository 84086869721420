import React, { useState } from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import {
  Container,
  LoadingOverlay,
  Table,
  Button,
  Modal,
  Text,
  Group,
  Grid,
  Col,
} from '@mantine/core'
import { FiDelete, FiPlusSquare } from 'react-icons/fi'
import { RiArrowGoBackFill } from 'react-icons/ri'
import { navigate } from 'gatsby'
import { useNotifications } from '@mantine/notifications'

import {
  useDeleteTracker,
  useGetTrackerById,
  useGetTrackerEntriesByTrackerId,
} from '@hooks/trackers'
import PageTitle from '@components/PageTitle'
import useIsMobile from '@hooks/useIsMobile'

const DeleteButton = ({ trackerId }: { trackerId?: string }) => {
  const [open, setOpen] = useState(false)
  const { mutate, isLoading } = useDeleteTracker(trackerId)
  const ntfn = useNotifications()
  const isMobile = useIsMobile()

  if (!trackerId) return null

  return (
    <>
      <Button
        size="md"
        style={{ marginLeft: '0.5rem' }}
        leftIcon={<FiDelete />}
        color="red"
        variant="outline"
        onClick={() => setOpen(true)}
      >
        Delete
      </Button>
      <Modal
        opened={open}
        onClose={() => setOpen(false)}
        title="Are you sure?"
        hideCloseButton
      >
        <LoadingOverlay visible={isLoading} />

        <Text size="lg">
          This operation is not reversible.{' '}
          <strong>You will lose your logged data.</strong> Are you sure?
        </Text>
        <Grid
          style={{
            marginTop: '1rem',
          }}
          columns={isMobile ? 1 : 2}
        >
          <Col span={1}>
            <Button
              size="md"
              leftIcon={<FiDelete />}
              color="red"
              onClick={async () => {
                await mutate(trackerId)
                ntfn.showNotification({
                  message: 'Tracker deleted.',
                  color: 'green',
                })
                navigate('/app/trackers')
              }}
              fullWidth={isMobile}
            >
              Yes, delete
            </Button>
          </Col>
          <Col span={1}>
            <Button
              size="md"
              leftIcon={<RiArrowGoBackFill />}
              color="gray"
              onClick={() => setOpen(false)}
              variant="outline"
              fullWidth={isMobile}
            >
              No, cancel
            </Button>
          </Col>
        </Grid>
      </Modal>
    </>
  )
}

const ViewTracker = ({
  trackerId,
}: { trackerId?: string } & RouteComponentProps) => {
  const { data, isLoading } = useGetTrackerById(trackerId)
  const { data: entries, isLoading: entriesIsLoading } =
    useGetTrackerEntriesByTrackerId(trackerId)

  console.log('tracker', trackerId, data)
  console.log('entries', entries)
  const isMobile = useIsMobile()

  if (!data || isLoading || !entries || entriesIsLoading)
    return (
      <>
        <LoadingOverlay visible={true} />
      </>
    )

  return (
    <>
      <Container>
        <PageTitle
          title={
            <>
              {data.icon} {data.name}
            </>
          }
          subtitle="View tracker entries."
          rightSection={
            <Group
              grow={isMobile}
              style={{
                marginLeft: 'auto',
              }}
            >
              <Button
                leftIcon={<FiPlusSquare />}
                size="md"
                component={Link}
                to={`/app/trackers/${trackerId}/log`}
              >
                Log
              </Button>
              <DeleteButton trackerId={trackerId} />
            </Group>
          }
          breadcrumbs={[{ text: 'Trackers', to: '/app' }]}
        />

        <Table highlightOnHover striped>
          <thead>
            <tr>
              <th>Time</th>
              {data.fields.map(d => (
                <th key={d.name}>
                  {d.name} {!!d.units && <>({d.units})</>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {entries.map(entry => (
              <tr key={entry.id}>
                <td>
                  {new Date(entry.time).toLocaleDateString(undefined, {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                  })}
                </td>
                {data.fields.map(d => (
                  <td key={d.name}>
                    {entry.values.find(val => val.name == d.name)?.value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  )
}

export default ViewTracker

import React, { useState } from 'react'
import {
  Affix,
  Burger,
  Drawer,
  Group,
  Overlay,
  Paper,
  Text,
  ThemeIcon,
  theming,
  useMantineTheme,
} from '@mantine/core'
import { useNotifications } from '@mantine/notifications'
import { createUseStyles } from 'react-jss'
import { Link, navigate } from 'gatsby'
import { FiList, FiLogIn, FiLogOut, FiUser, FiUserPlus } from 'react-icons/fi'
import { IconType } from 'react-icons'

import TrackishLogoAndText from './Trackish'
import CommitHash from './CommitHash'
import supabase from '@util/supabase'
import { useAuth } from '@context/Auth'

const useStyles = createUseStyles(
  theme => ({
    paper: {
      background: theme.colors.dark[6],
      position: 'relative',
      margin: '0.25rem 0',

      '&:hover': {
        background: theme.colors.dark[5],
      },
    },
  }),
  { theming },
)

const DrawerItem = ({
  text,
  Icon,
  link,
  color,
  onClick,
}: {
  text: string
  Icon: IconType
  color?: string
  link: string
  onClick?: Function
}) => {
  const styles = useStyles()

  return (
    <Paper padding="sm" className={styles.paper}>
      <Group>
        <ThemeIcon size="xl" color={color}>
          <Icon size={24} />
        </ThemeIcon>
        <Text>{text}</Text>
      </Group>
      {/* @ts-ignore */}
      <Overlay opacity={0} component={Link} to={link} onClick={onClick} />
    </Paper>
  )
}

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const theme = useMantineTheme()
  const notifications = useNotifications()
  const { auth } = useAuth()

  return (
    <>
      <Affix
        position={{
          top: 0,
          right: 0,
          left: 0,
        }}
      >
        <Paper padding="md" style={{ background: theme.colors.dark[6] }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <Burger
                opened={drawerOpen}
                onClick={() => {
                  setDrawerOpen(true)
                }}
              />
              <Drawer
                opened={drawerOpen}
                padding="md"
                onClose={() => {
                  setDrawerOpen(false)
                }}
                hideCloseButton
                transition="fade"
                noFocusTrap
                title={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Burger
                      opened={drawerOpen}
                      onClick={() => {
                        setDrawerOpen(false)
                      }}
                    />
                    <TrackishLogoAndText style={{ marginLeft: '0.5rem' }} />
                  </div>
                }
              >
                {auth.user && (
                  <>
                    <DrawerItem
                      text="Trackers"
                      Icon={FiList}
                      link="/app/trackers"
                      color="orange"
                      onClick={() => {
                        setDrawerOpen(false)
                      }}
                    />
                    <DrawerItem
                      text="Account"
                      Icon={FiUser}
                      link="/app/account"
                      color="lime"
                      onClick={() => {
                        setDrawerOpen(false)
                      }}
                    />
                    <DrawerItem
                      text="Logout"
                      Icon={FiLogOut}
                      color="red"
                      link="#"
                      onClick={async (evt: MouseEvent) => {
                        evt.preventDefault()
                        await supabase.auth.signOut()
                        notifications.showNotification({
                          message: "You're now logged out.",
                        })
                        setDrawerOpen(false)
                        navigate('/app')
                      }}
                    />
                  </>
                )}
                {!auth.user && (
                  <>
                    <DrawerItem
                      text="Sign up"
                      Icon={FiUserPlus}
                      link="/app/signup"
                      color="orange"
                      onClick={() => {
                        setDrawerOpen(false)
                      }}
                    />
                    <DrawerItem
                      text="Login"
                      Icon={FiLogIn}
                      link="/app/login"
                      color="lime"
                      onClick={() => {
                        setDrawerOpen(false)
                      }}
                    />
                  </>
                )}
                <Text
                  align="center"
                  style={{ color: theme.colors.dark[2], marginTop: '2rem' }}
                >
                  tracki.sh version <CommitHash />
                </Text>
              </Drawer>
            </div>
            <TrackishLogoAndText
              style={{
                marginLeft: '0.5rem',
              }}
            />
          </div>
        </Paper>
      </Affix>
      <div style={{ height: 66 }} />
    </>
  )
}

export default Header

import React from 'react'
import { navigate } from 'gatsby'
import { RouteComponentProps } from '@reach/router'

// import useAuth from '@hooks/useAuth'

const NonAuthed = ({
  component: Component,
  ...rest
}: {
  component: React.PropsWithChildren<any>
} & RouteComponentProps) => {
  //   const { auth } = useAuth()

  //   if (auth) {
  //     navigate('/app', { replace: true })
  //     return null
  //   }

  return <Component {...rest} />
}

export default NonAuthed

import React, { useState, useEffect, ReactNode } from 'react'
import { Router } from '@reach/router'
import { Session, User } from '@supabase/supabase-js'
import supabase from '@util/supabase'
import { LoadingOverlay } from '@mantine/core'

import AuthContext from '@context/Auth'
import NonAuthed from '@components/auth/NonAuthed'
import Authed from '@components/auth/Authed'

import Signup from './signup'
import Login from './login'
import Header from './Header'
import Trackers from './trackers'
import CreateTracker from './trackers/CreateTracker'
import ViewTracker from './trackers/ViewTracker'
import Log from './trackers/Log'

const useLoadAuthProvider = () => {
  const [auth, setAuth] = useState<{
    user: User | null
    session: Session | null
  }>({
    user: null,
    session: null,
  })
  const [authLoaded, setAuthLoaded] = useState(false)
  const [userDataLoaded, setUserDataLoaded] = useState(false)

  useEffect(() => {
    setAuth({
      user: supabase.auth.user(),
      session: supabase.auth.session(),
    })
    setAuthLoaded(true)

    const sub = supabase.auth.onAuthStateChange((event, session) => {
      setAuth({
        session,
        user: session?.user ?? null,
      })
      setAuthLoaded(true)
    })

    return () => {
      sub.data?.unsubscribe()
    }
  }, [])

  const [userData, setUserData] = useState<any>(null)

  useEffect(() => {
    if (!auth.user) {
      setUserDataLoaded(true)
      return
    }

    const getUserData = async () => {
      const userData = await supabase.from<any>('users').select('*').single()
      setUserData(userData)
      setUserDataLoaded(true)
    }

    getUserData()
  }, [auth.user])

  return { auth, userData, loaded: authLoaded && userDataLoaded }
}

const Routes = () => {
  const { auth, userData, loaded } = useLoadAuthProvider()

  if (!loaded) return <LoadingOverlay visible />

  return (
    <AuthContext.Provider value={{ auth, userData }}>
      <Header />

      <Router>
        <NonAuthed component={Signup} path="/app/signup" />
        <NonAuthed component={Login} path="/app/login" />
        <Authed default>
          <Trackers path="/app/trackers" default />
          <CreateTracker path="/app/trackers/add" />
          <ViewTracker path="/app/trackers/:trackerId" />
          <Log path="/app/trackers/:trackerId/log" />
          {/* <Sources path="/app/sources/*" />
        <Conversations path="/app/conversations/*" />
        <Agents path="/app/agents/*" />
        <Entities path="/app/entities/*" />
        <User path="/app/user/*" />
        <Testing path="/app/testing" /> */}
        </Authed>
      </Router>
    </AuthContext.Provider>
  )
}

export default Routes

import React, { useEffect, useState } from 'react'
import { GlobalStyles, MantineProvider } from '@mantine/core'
import { NotificationsProvider } from '@mantine/notifications'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Helmet } from 'react-helmet'
import { navigate } from '@reach/router'
import { PostgrestError } from '@supabase/supabase-js'

// import FirebaseContext from '@context/Firebase'
import ErrorBoundary from '@components/ErrorBoundary'

import Routes from './Routes'

const handleGlobalErr = (err: Error | PostgrestError | any) => {
  if (err?.message === 'JWT expired') {
    console.log('JWT EXPIRED', err, Object.keys(err))
    navigate('/app/login')
    return
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: handleGlobalErr,
    },
    queries: {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      onError: handleGlobalErr,
    },
  },
})

const App = () => {
  // const [firebaseContext, setFirebaseContext] =
  //   useState<firebase.default.app.App>()

  // useEffect(() => {
  //   const loadFirebase = async () => {
  //     const [lazyFirebase] = await Promise.all([
  //       (await import('firebase/app')).default,
  //       import('firebase/firestore'),
  //       import('firebase/auth'),
  //       import('firebase/analytics'),
  //       import('firebase/performance'),
  //     ])

  //     setFirebaseContext(
  //       lazyFirebase.apps.length
  //         ? lazyFirebase.apps[0]
  //         : lazyFirebase.initializeApp({
  //             apiKey: 'AIzaSyBxW0uIzFAVV7bQYTq-UMUmRgPuKl13gcQ',
  //             authDomain: 'tracki-sh.firebaseapp.com',
  //             projectId: 'tracki-sh',
  //             storageBucket: 'tracki-sh.appspot.com',
  //             messagingSenderId: '63145258684',
  //             appId: '1:63145258684:web:de6aec0a27e891d474eab9',
  //             measurementId: 'G-BTR101GKZ0',
  //           }),
  //     )
  //   }

  //   loadFirebase()
  // }, [])

  // can show spinner
  // if (!firebaseContext) return null

  // const isSSR = typeof window === 'undefined'

  // if (process.env.NODE_ENV === 'development') {
  //   // firebase.firestore().settings({
  //   //   host: 'localhost:8080',
  //   //   ssl: false,
  //   // })
  // } else {
  //   if (!isSSR) {
  //     firebaseContext.analytics()
  //     firebaseContext.performance()
  //   }
  // }

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Raleway&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          theme={{
            colorScheme: 'dark',
            headings: {
              fontFamily: 'Abril Fatface',
            },
            fontFamily: 'Raleway',
          }}
        >
          <NotificationsProvider>
            <GlobalStyles />
            {/* <FirebaseContext.Provider value={firebaseContext}> */}
            <ErrorBoundary fallback={<>there was an error :(</>}>
              <Routes />
            </ErrorBoundary>
            {/* </FirebaseContext.Provider> */}
            <ReactQueryDevtools />
          </NotificationsProvider>
        </MantineProvider>
      </QueryClientProvider>
    </>
  )
}

export default App

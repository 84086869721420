import { useForm as mantineUseForm } from '@mantine/hooks'
import { PostgrestError } from '@supabase/supabase-js'
import { useState } from 'react'
import { createUseStyles } from 'react-jss'

export const useFormStyles = createUseStyles({
  form: {
    position: 'relative',
    marginTop: '1rem',

    '& > *': {
      marginBottom: '1rem',
    },
  },
})

export function useForm<TFormData extends { [key: string]: any }>(opts: {
  form: Parameters<typeof mantineUseForm>[0]
}) {
  const form = mantineUseForm<TFormData>(opts.form)
  const [submitting, setSubmitting] = useState(false)
  const [generalError, setGeneralError] = useState<
    null | Error | PostgrestError
  >(null)
  const styles = useFormStyles()

  return {
    form,
    submitting,
    setSubmitting,
    generalError,
    setGeneralError,
    styles,
  }
}

export default useForm

import React from 'react'
import {
  Alert,
  Button,
  CloseButton,
  Col,
  Container,
  Grid,
  LoadingOverlay,
  NativeSelect,
  Paper,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core'
import { RouteComponentProps } from '@reach/router'
import { useMantineTheme } from '@mantine/core'
import { useNotifications } from '@mantine/notifications'
import { navigate } from 'gatsby'
import { FiPlus } from 'react-icons/fi'

import useIsMobile from '@hooks/useIsMobile'
import supabase from '@util/supabase'
import PageTitle from '@components/PageTitle'
import {
  Field,
  FieldSelect,
  FieldTypes,
  isSelectField,
  Tracker,
} from '@hooks/trackers'
import useForm from '@hooks/useForm'
import EmojiPicker from '@components/EmojiPicker'

const CreateTracker = ({}: {} & RouteComponentProps) => {
  const theme = useMantineTheme()
  const isMobile = useIsMobile()

  const form = useForm<Tracker>({
    form: {
      initialValues: {
        name: '',
        icon: '⚖️',
        fields: [
          {
            name: '',
            type: 'text',
            units: '',
          },
        ],
      },

      validationRules: {
        name: val => !!val,
        fields: (value: Tracker['fields']) => {
          return (
            value.every(val => !!val.name) &&
            value.every(val => !isSelectField(val) || val.options?.length > 1)
          )
        },
      },
    },
  })

  const ntfn = useNotifications()

  const onSubmit = async (values: Tracker) => {
    console.log('form values', values)
    form.setGeneralError(null)
    form.setSubmitting(true)

    const resp = await supabase.from<Tracker>('trackers').insert([
      {
        user_id: supabase.auth.user()?.id,
        name: values.name,
        icon: values.icon,
        fields: values.fields,
      },
    ])

    console.log('result', resp)

    form.setSubmitting(false)

    if (resp.error) {
      form.setGeneralError(resp.error)
      return
    }

    ntfn.showNotification({
      message: 'Tracker created!',
      color: 'green',
    })
    navigate(`/app/trackers/${resp.body[0].id}`)
  }

  return (
    <Container>
      <PageTitle
        title="Create Tracker"
        subtitle="Create a tracker to start logging."
        breadcrumbs={[{ text: 'Trackers', to: '/app/trackers' }]}
      />

      <form
        onSubmit={form.form.onSubmit(onSubmit)}
        className={form.styles.form}
      >
        <LoadingOverlay visible={form.submitting} />

        {form.generalError && (
          <Alert color="red" title="Something went wrong.">
            {form.generalError.message}
          </Alert>
        )}

        <TextInput
          required
          label="Name"
          name="name"
          placeholder="My Weight"
          description="Give a name to your tracker."
          error={form.form.errors.name && 'This field is required.'}
          value={form.form.values.name}
          onChange={event =>
            form.form.setFieldValue('name', event.currentTarget.value)
          }
          size="md"
        />

        <EmojiPicker
          inputWrapper={{
            required: true,
            label: 'Icon',
            description: 'Pick an emoji for your tracker.',
            children: null,
            size: 'md',
          }}
          picker={{
            onSelect: emoji => {
              form.form.setFieldValue('icon', emoji.native)
            },
          }}
          value={form.form.values.icon}
        />

        <Text>Fields</Text>
        {form.form.values.fields.map((field, i) => (
          <Paper
            key={i}
            style={{
              background: theme.colors.dark[6],
              position: 'relative',
            }}
            padding="md"
          >
            <Text size="md">Field</Text>
            <Grid columns={isMobile ? 1 : isSelectField(field) ? 4 : 3}>
              <CloseButton
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
                title="Remove Field"
                onClick={() => {
                  form.form.setFieldValue('fields', [
                    ...form.form.values.fields.slice(0, i),
                    ...form.form.values.fields.slice(i + 1),
                  ])
                }}
                disabled={i === 0 && form.form.values.fields.length === 1}
              />
              <Col span={1}>
                <TextInput
                  required
                  label="Field Name"
                  name={`field-${i}-name`}
                  placeholder="Weight"
                  description="Give a name to your tracker."
                  error={
                    form.form.errors.fields &&
                    !field.name &&
                    'This field is required.'
                  }
                  value={field.name}
                  onChange={event =>
                    form.form.setFieldValue('fields', [
                      ...form.form.values.fields.slice(0, i),
                      {
                        ...field,
                        name: event.target.value,
                      },
                      ...form.form.values.fields.slice(i + 1),
                    ])
                  }
                  size="md"
                />
              </Col>
              <Col span={1}>
                <NativeSelect
                  data={[
                    { value: 'number', label: 'Number' },
                    { value: 'emoji', label: 'Emoji' },
                    { value: 'toggle', label: 'True/False' },
                    { value: 'select', label: 'Select' },
                  ]}
                  label="Field Type"
                  description="Type of data that you want to track."
                  required
                  value={field.type}
                  onChange={event =>
                    form.form.setFieldValue('fields', [
                      ...form.form.values.fields.slice(0, i),
                      {
                        ...field,
                        type: event.target.value as FieldTypes,
                      },
                      ...form.form.values.fields.slice(i + 1),
                    ])
                  }
                  size="md"
                />
              </Col>
              {isSelectField(field) && (
                <Col span={1}>
                  <Textarea
                    required
                    label="Options"
                    description="Define the options to select from. One option per line."
                    placeholder={`Great\nGood\nBad\nTerrible`}
                    value={field.options?.join('\n') ?? ''}
                    onChange={event =>
                      form.form.setFieldValue('fields', [
                        ...form.form.values.fields.slice(0, i),
                        {
                          ...field,
                          options: event.target.value.split('\n'),
                        },
                        ...form.form.values.fields.slice(i + 1),
                      ])
                    }
                    onBlur={event => {
                      form.form.setFieldValue('fields', [
                        ...form.form.values.fields.slice(0, i),
                        {
                          ...field,
                          options: [
                            ...new Set(
                              field.options.filter(str => str.length > 0),
                            ),
                          ],
                        },
                        ...form.form.values.fields.slice(i + 1),
                      ])
                    }}
                    error={
                      form.form.errors.fields &&
                      field.options?.length < 2 &&
                      'Need at least 2 options.'
                    }
                  />
                </Col>
              )}
              <Col span={1}>
                <TextInput
                  label="Field Units"
                  name={`field-${i}-units`}
                  placeholder="lbs"
                  description="The units for this data, if applicable."
                  value={field.units}
                  onChange={event =>
                    form.form.setFieldValue('fields', [
                      ...form.form.values.fields.slice(0, i),
                      {
                        ...field,
                        units: event.target.value,
                      },
                      ...form.form.values.fields.slice(i + 1),
                    ])
                  }
                  size="md"
                />
              </Col>
            </Grid>
          </Paper>
        ))}

        <Button
          variant="outline"
          leftIcon={<FiPlus />}
          onClick={() => {
            form.form.setFieldValue('fields', [
              ...form.form.values.fields,
              { name: '', type: 'number', units: '' },
            ])
          }}
        >
          Add Field
        </Button>

        <div />

        <Button type="submit" fullWidth size="lg">
          Create Tracker
        </Button>
      </form>
    </Container>
  )
}

export default CreateTracker

import React from 'react'
import { RouteComponentProps } from '@reach/router'
import {
  Container,
  LoadingOverlay,
  Alert,
  Button,
  Textarea,
  InputWrapper,
  Input,
  NativeSelect,
} from '@mantine/core'
import { TextInput } from '@mantine/core'
import { FiPlus } from 'react-icons/fi'
import { navigate } from 'gatsby'
import { useNotifications } from '@mantine/notifications'

import {
  isSelectField,
  Tracker,
  TrackerEntry,
  useGetTrackerById,
  useLogToTracker,
} from '@hooks/trackers'
import useForm from '@hooks/useForm'
import PageTitle from '@components/PageTitle'
import EmojiPicker from '@components/EmojiPicker'
import { format } from 'date-fns'

const LogForm = ({
  fields,
  onSubmit,
}: {
  fields: Tracker['fields']
  onSubmit: (values: Partial<TrackerEntry>) => void
}) => {
  const form = useForm({
    form: {
      initialValues: fields.reduce(
        (obj, field) => ({
          ...obj,
          [field.name]: isSelectField(field) ? field.options[0] : '',
        }),
        {
          time: new Date(),
          note: '',
        },
      ),
    },
  })

  const handleSubmit = async (values: Partial<TrackerEntry>) => {
    const allEmpty = fields.every(field => !values[field.name])
    if (allEmpty) {
      form.setGeneralError(
        new Error(
          'You need to enter at least 1 of the values to log something!',
        ),
      )
      return
    }

    form.setSubmitting(true)
    try {
      await onSubmit({
        time: values.time,
        values: fields.map(field => ({
          name: field.name,
          value: values[field.name],
        })),
        note: values.note,
      })
    } catch (e) {
      form.setGeneralError(e)
    }
    form.setSubmitting(false)
  }

  return (
    <>
      <Container size="sm">
        <form
          onSubmit={form.form.onSubmit(handleSubmit)}
          className={form.styles.form}
        >
          <LoadingOverlay visible={form.submitting} />

          {form.generalError && (
            <Alert color="red" title="Something went wrong.">
              {form.generalError.message}
            </Alert>
          )}

          <InputWrapper id="time" label="Date & Time" required>
            <Input
              type="datetime-local"
              step="1"
              id="time"
              defaultValue={format(new Date(), "yyyy-MM-dd'T'hh:mm:ss")}
              onChange={evt => {
                if (!evt.target.value) return

                form.form.setFieldValue('time', new Date(evt.target.value))
              }}
              required
            />
          </InputWrapper>

          {fields.map(field => {
            if (field.type === 'emoji')
              return (
                <EmojiPicker
                  key={field.name}
                  inputWrapper={{
                    label: field.name,
                    description: field.units,
                    children: null,
                    size: 'md',
                  }}
                  picker={{
                    onSelect: emoji => {
                      form.form.setFieldValue(field.name, emoji.native)
                    },
                  }}
                  value={form.form.values[field.name]}
                />
              )

            if (field.type === 'number')
              return (
                <TextInput
                  key={field.name}
                  name={field.name}
                  label={field.name}
                  value={form.form.values[field.name]}
                  onChange={event => {
                    return form.form.setFieldValue(
                      field.name,
                      event.target.value ? +event.target.value : '',
                    )
                  }}
                  description={field.units}
                  type={field.type}
                />
              )

            if (isSelectField(field))
              return (
                <NativeSelect
                  key={field.name}
                  name={field.name}
                  label={field.name}
                  value={form.form.values[field.name]}
                  data={field.options.map(d => ({
                    value: d,
                    label: d,
                  }))}
                  onChange={evt =>
                    form.form.setFieldValue(field.name, evt.currentTarget.value)
                  }
                />
              )
          })}

          <Textarea
            name="note"
            label="Note"
            description="Take any notes or extra information"
            value={form.form.values.note}
            onChange={evt => form.form.setFieldValue('note', evt.target.value)}
          />

          <Button type="submit" leftIcon={<FiPlus />} fullWidth>
            Log It
          </Button>
        </form>
      </Container>
    </>
  )
}

const Log = ({ trackerId }: { trackerId?: string } & RouteComponentProps) => {
  const { data, isLoading } = useGetTrackerById(trackerId)
  const mutation = useLogToTracker(trackerId)
  const notifications = useNotifications()
  console.log('tracker', trackerId, data)

  if (!data || isLoading)
    return (
      <>
        <LoadingOverlay visible={true} />
      </>
    )

  return (
    <>
      <Container>
        <PageTitle
          title="Log Entry"
          subtitle={
            <>
              Log an entry to {data.icon}{' '}
              <span style={{ fontWeight: 'bold' }}>{data.name}</span>.
            </>
          }
          breadcrumbs={[
            { text: 'Trackers', to: '/app/trackers' },
            {
              text: `${data.icon} ${data.name}`,
              to: `/app/trackers/${trackerId}`,
            },
          ]}
        />

        <LogForm
          fields={data.fields}
          onSubmit={async values => {
            console.log('submitted', values)
            await mutation.mutate(values)
            notifications.showNotification({
              message: 'Log entry added!',
              color: 'green',
            })
            navigate(`/app/trackers/${trackerId}`)
          }}
        />
      </Container>
    </>
  )
}

export default Log

import { createClient, Session, User } from '@supabase/supabase-js'

const supabase = createClient(
  // supabase url
  'https://fmoywulxthlhgcqybste.supabase.co',
  // anon key
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyNzI0MzAzNiwiZXhwIjoxOTQyODE5MDM2fQ.uiBDjliCrYtJHWMLHbRw_3bHPqQLHftqj-fP9QTeIK4',
)

export default supabase

import { Session, User } from '@supabase/gotrue-js'
import { createContext, useContext } from 'react'

const AuthContext = createContext<{
  auth: {
    user: User | null
    session: Session | null
  }
  userData: any | null
}>({
  auth: {
    user: null,
    session: null,
  },
  userData: null,
})

export default AuthContext

export function useAuth() {
  const ctx = useContext(AuthContext)
  return ctx
}

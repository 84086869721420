import React, { useState } from 'react'
import {
  Container,
  Text,
  Button,
  Alert,
  TextInput,
  LoadingOverlay,
  PasswordInput,
  Checkbox,
  InputWrapper,
} from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { Link, navigate } from 'gatsby'
import { createUseStyles } from 'react-jss'

import supabase from '@util/supabase'
import PageTitle from '@components/PageTitle'

type FormData = {
  name: string
  email: string
  password: string
  tos: boolean
}

const useStyles = createUseStyles({
  form: {
    position: 'relative',
    marginTop: '1rem',

    '& > *': {
      marginBottom: '1rem',
    },
  },
})

const Signup = () => {
  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      password: '',
      tos: false,
    },

    validationRules: {
      name: val => !!val,
      email: value => /^\S+@\S+$/.test(value),
      password: value => value.length >= 6,
      tos: val => !!val,
    },
  })
  const [submitting, setSubmitting] = useState(false)
  const [generalError, setGeneralError] = useState<null | Error>(null)
  const styles = useStyles()

  const onSubmit = async ({ name, email, password }: FormData) => {
    setGeneralError(null)
    setSubmitting(true)
    try {
      const { user, session, error } = await supabase.auth.signUp({
        email,
        password,
      })

      if (error?.message) {
        setGeneralError(error)
        return
      }

      if (!user) {
        setGeneralError(
          new Error(
            'Not sure what. Our developers have been notified. Try again?',
          ),
        )
        return
      }

      await supabase.from('users').update({
        name,
      })

      navigate('/app')
    } catch (err) {
      setGeneralError(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Container size="xs">
      <PageTitle
        title="Sign up"
        subtitle={
          <>
            Aloha 👋 <br />
            Sign up to tracki.sh to get started.
          </>
        }
      />
      <form onSubmit={form.onSubmit(onSubmit)} className={styles.form}>
        {generalError && (
          <Alert color="red" title="Something went wrong.">
            {generalError.message}
          </Alert>
        )}
        <LoadingOverlay visible={submitting} />
        <TextInput
          required
          label="Name"
          name="name"
          placeholder="Lisa Smith"
          error={form.errors.name && 'Please enter your name.'}
          value={form.values.name}
          onChange={event =>
            form.setFieldValue('name', event.currentTarget.value)
          }
        />
        <TextInput
          required
          label="Email"
          name="email"
          placeholder="lisa@tracki.sh"
          error={form.errors.email && 'Please enter a valid email.'}
          value={form.values.email}
          onChange={event =>
            form.setFieldValue('email', event.currentTarget.value)
          }
        />
        <PasswordInput
          required
          placeholder="Password"
          label="Password"
          name="password"
          showPasswordLabel="Show password"
          hidePasswordLabel="Hide password"
          value={form.values.password}
          onChange={event =>
            form.setFieldValue('password', event.currentTarget.value)
          }
          error={
            form.errors.password && 'Password should be at least 6 characters.'
          }
        />

        <InputWrapper
          error={
            form.errors.tos &&
            'You must accept the Terms of Service to sign up.'
          }
        >
          <Checkbox
            label={
              <>
                I have read and agree to the{' '}
                <Text variant="link" component={Link} to="/legal/tos">
                  Terms of Service
                </Text>
                .
              </>
            }
            checked={form.values.tos}
            onChange={event =>
              form.setFieldValue('tos', event.currentTarget.checked)
            }
            name="tos"
          />
        </InputWrapper>

        <Button type="submit" disabled={submitting} fullWidth>
          Sign Up
        </Button>

        <Text align="center">
          Already have an account?{' '}
          <Text variant="link" component={Link} to="/app/login">
            Login.
          </Text>
        </Text>
      </form>
    </Container>
  )
}

export default Signup

import { PostgrestError } from '@supabase/supabase-js'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import supabase from '@util/supabase'

export interface TrackerEntryValue {
  name: string
  units?: string
  value: string | number
}

export interface TrackerEntry {
  id: string
  user_id: string
  tracker_id: string
  time: number
  values: TrackerEntryValue[]
  note: string
}

export type FieldTypes = 'number' | 'emoji' | 'toggle' | 'select'

export interface Field {
  name: string
  type: FieldTypes
  units?: string
}

export interface FieldSelect {
  name: string
  type: 'select'
  units?: string
  options: string[]
}

export interface Tracker {
  id: string
  user_id: string
  name: string
  icon: string
  fields: (Field | FieldSelect)[]
}

export function isSelectField(
  field: Field | FieldSelect,
): field is FieldSelect {
  return field.type === 'select'
}

export const useGetTrackers = () =>
  useQuery<Tracker[], PostgrestError>('trackers', async () => {
    const resp = await supabase.from<Tracker>('trackers').select('*')
    if (resp.error) throw resp.error
    return resp.data
  })

export const useGetTrackerById = (uuid?: string) =>
  useQuery<Tracker, PostgrestError>(
    ['trackers', uuid],
    async key => {
      const resp = await supabase
        .from('trackers')
        .select()
        .eq('id', uuid)
        .single()
      if (resp.error) throw resp.error
      return resp.data
    },
    {
      enabled: !!uuid,
    },
  )

export const useLogToTracker = (uuid?: string) => {
  const client = useQueryClient()
  return useMutation(
    async (data: Partial<TrackerEntry>) => {
      const resp = await supabase.from('tracker_entries').insert([
        {
          user_id: supabase.auth.user()?.id,
          tracker_id: uuid,
          time: data.time,
          note: data.note,
          values: data.values,
        },
      ])
      if (resp.error) throw resp.error
      return resp.data
    },
    {
      onSuccess: data => {
        client.invalidateQueries(['tracker_entries', uuid])
      },
    },
  )
}

export const useGetTrackerEntriesByTrackerId = (uuid?: string) =>
  useQuery<TrackerEntry[], PostgrestError>(
    ['tracker_entries', uuid],
    async key => {
      const resp = await supabase
        .from('tracker_entries')
        .select()
        .eq('tracker_id', uuid)
        .order('time', {
          ascending: false,
        })
      if (resp.error) throw resp.error
      return resp.data
    },
    {
      enabled: !!uuid,
    },
  )

export const useDeleteTracker = (uuid?: string) => {
  const client = useQueryClient()
  return useMutation(
    async () => {
      const resp = await supabase.from('trackers').delete().eq('id', uuid)
      if (resp.error) throw resp.error
      return resp.data
    },
    {
      onSuccess: data => {
        client.invalidateQueries(['trackers'])
      },
    },
  )
}

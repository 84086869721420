import React from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import {
  Text,
  Grid,
  Paper,
  Col,
  Button,
  Group,
  Container,
  Alert,
} from '@mantine/core'
import { FiPlus, FiPlusSquare } from 'react-icons/fi'
import { HiPresentationChartLine } from 'react-icons/hi'
import { useMantineTheme } from '@mantine/core'

import useIsMobile from '@hooks/useIsMobile'
import { useGetTrackers } from '@hooks/trackers'
import PageTitle from '@components/PageTitle'

const Trackers = ({}: {} & RouteComponentProps) => {
  const isMobile = useIsMobile()
  const columns = isMobile ? 1 : 3
  const theme = useMantineTheme()

  const { data, error } = useGetTrackers()

  console.log('trackers', data, error)

  return (
    <Container>
      <PageTitle
        title="Trackers"
        subtitle="All your trackers."
        rightSection={
          <Button
            style={{
              marginLeft: 'auto',
            }}
            leftIcon={<FiPlus />}
            size="md"
            component={Link}
            to="/app/trackers/add"
          >
            Create new tracker
          </Button>
        }
      />

      {error && (
        <Alert color="red" title="Uh oh, there was an error :(">
          {error.code} - {error.message}
        </Alert>
      )}

      {data?.length === 0 && (
        <>
          <Text>
            <Text variant="link" component={Link} to="/app/trackers/add">
              Create a tracker
            </Text>{' '}
            to start tracking!
          </Text>
        </>
      )}

      {(data?.length ?? 0) > 0 && (
        <Grid columns={columns}>
          {data?.map(tracker => (
            <Col span={1} key={tracker.id}>
              <Paper
                shadow="xl"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: theme.colors.dark[6],
                }}
                padding="sm"
              >
                <Text
                  style={{
                    fontSize: '3.5rem',
                  }}
                >
                  {tracker.icon}
                </Text>
                <Text size="xl">{tracker.name}</Text>
                <Group
                  spacing="xs"
                  style={{
                    marginTop: '1rem',
                  }}
                >
                  <Button
                    variant="outline"
                    leftIcon={<HiPresentationChartLine />}
                    size="md"
                    component={Link}
                    to={`/app/trackers/${tracker.id}`}
                  >
                    View
                  </Button>
                  <Button
                    leftIcon={<FiPlusSquare />}
                    size="md"
                    component={Link}
                    to={`/app/trackers/${tracker.id}/log`}
                  >
                    Log
                  </Button>
                </Group>
              </Paper>
            </Col>
          ))}
        </Grid>
      )}
    </Container>
  )
}

export default Trackers

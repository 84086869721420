import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

const CommitHash = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          gitCommit(latest: { eq: true }) {
            hash
          }
        }
      `}
      render={data => (
        <span title={data.gitCommit.hash}>
          {data.gitCommit.hash.substring(0, 8)}
        </span>
      )}
    />
  )
}

export default CommitHash

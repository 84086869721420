import React, { ReactNode } from 'react'
import { navigate } from 'gatsby'
import { RouteComponentProps } from '@reach/router'

import { useAuth } from '@context/Auth'

// import useFirebase from '@hooks/useFirebase'

const Authed = ({
  children,
}: { children: ReactNode } & RouteComponentProps) => {
  const { auth } = useAuth()
  // const firebase = useFirebase()

  // const qs = new URLSearchParams(window.location.search)
  // if (qs.has('custom')) {
  //   const token = qs.get('custom')
  //   firebase
  //     .auth()
  //     .signOut()
  //     .then(() => firebase.auth().signInWithCustomToken(token))
  // }

  if (!auth.user) {
    navigate('/app/login', { replace: true })
    return null
  }

  return <>{children}</>
}

export default Authed

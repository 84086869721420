import React, { useState } from 'react'
import { useForm } from '@mantine/hooks'
import {
  Container,
  Title,
  Text,
  Alert,
  LoadingOverlay,
  TextInput,
  PasswordInput,
  Button,
} from '@mantine/core'
import { createUseStyles } from 'react-jss'
import { Link, navigate } from 'gatsby'

import supabase from '@util/supabase'
import PageTitle from '@components/PageTitle'

type FormData = {
  email: string
  password: string
}

const useStyles = createUseStyles({
  form: {
    position: 'relative',
    marginTop: '1rem',

    '& > *': {
      marginBottom: '1rem',
    },
  },
})

const Login = () => {
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validationRules: {
      email: value => /^\S+@\S+$/.test(value),
      password: value => value.length >= 6,
    },
  })
  const [submitting, setSubmitting] = useState(false)
  const [generalError, setGeneralError] = useState<null | Error>(null)
  const styles = useStyles()

  const onSubmit = async ({ email, password }: FormData) => {
    setGeneralError(null)
    setSubmitting(true)
    try {
      const { user, session, error } = await supabase.auth.signIn({
        email,
        password,
      })

      if (error?.message) {
        setGeneralError(error)
        return
      }

      if (!user) {
        setGeneralError(
          new Error(
            'Not sure what. Our developers have been notified. Try again?',
          ),
        )
        return
      }

      navigate('/app')
    } catch (err) {
      setGeneralError(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Container size="xs">
      <PageTitle
        title="Login"
        subtitle={
          <>
            Aloha 👋 <br />
            Login to tracki.sh to get started.
          </>
        }
      />
      <form onSubmit={form.onSubmit(onSubmit)} className={styles.form}>
        {generalError && (
          <Alert color="red" title="Something went wrong.">
            {generalError.message}
          </Alert>
        )}
        <LoadingOverlay visible={submitting} />
        <TextInput
          required
          label="Email"
          name="email"
          placeholder="lisa@tracki.sh"
          error={form.errors.email && 'Please enter a valid email.'}
          value={form.values.email}
          onChange={event =>
            form.setFieldValue('email', event.currentTarget.value)
          }
        />
        <PasswordInput
          required
          placeholder="Password"
          label="Password"
          name="password"
          showPasswordLabel="Show password"
          hidePasswordLabel="Hide password"
          value={form.values.password}
          onChange={event =>
            form.setFieldValue('password', event.currentTarget.value)
          }
          error={
            form.errors.password && 'Password should be at least 6 characters.'
          }
        />

        <Button type="submit" disabled={submitting} fullWidth>
          Login
        </Button>

        <Text align="center">
          Don't have an account?{' '}
          <Text variant="link" component={Link} to="/app/signup">
            Sign Up.
          </Text>
        </Text>
      </form>
    </Container>
  )
}

export default Login

import React, { useState, ComponentProps } from 'react'
import { Input, InputWrapper, Popover } from '@mantine/core'
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import { useId } from '@mantine/hooks'

const EmojiPicker = ({
  picker,
  inputWrapper,
  value,
}: {
  picker: ComponentProps<typeof Picker>
  inputWrapper: ComponentProps<typeof InputWrapper>
  value: string
}) => {
  const [open, setOpen] = useState(false)
  const uuid = useId()

  return (
    <InputWrapper {...inputWrapper} labelProps={{ htmlFor: uuid }}>
      <Popover
        opened={open}
        onClose={() => setOpen(false)}
        target={
          <Input
            onClick={() => setOpen(true)}
            onFocus={() => setOpen(true)}
            value={value}
            readOnly
            id={uuid}
          />
        }
        styles={{
          body: { width: '100%' },
          root: {
            width: '100%',
          },
        }}
        position="bottom"
        withArrow
        spacing={0}
      >
        <Picker
          theme="dark"
          native
          autoFocus
          title=""
          emoji=""
          showPreview={false}
          showSkinTones={false}
          perLine={7}
          {...picker}
          style={{
            ...picker?.style,
          }}
          onSelect={emoji => {
            picker?.onSelect?.(emoji)
            setOpen(false)
          }}
        />
      </Popover>
    </InputWrapper>
  )
}

export default EmojiPicker
